const postalCodes = [
    {
        country: 'United States',
        code: '+1'
    },
    {
        country: 'Australia',
        code: '+61'
    },
    {
        country: 'Austria',
        code: '+43'
    },
    {
        country: 'Bangladesh',
        code: '+880'
    },
    {
        country: 'Belgium',
        code: '+32'
    },
    {
        country: 'Bhutan',
        code: '+975'
    },
    {
        country: 'Brazil',
        code: '+55'
    },
    {
        country: 'Canada',
        code: '+1'
    },
    // {
    //     country: 'China',
    //     code: '+86'
    // },
    // {
    //     country: 'Croatia',
    //     code: '+385'
    // },
    // {
    //     country: 'Cuba',
    //     code: '+53'
    // },
    {
        country: 'Cyprus',
        code: '+357'
    },
    {
        country: 'Czech Republic',
        code: '+420'
    },
    {
        country: 'Denmark',
        code: '+45'
    },
    {
        country: 'Egypt',
        code: '+20'
    },
    // {
    //     country: 'El Salvador',
    //     code: '+503'
    // },
    {
        country: 'Fiji',
        code: '+679'
    },
    {
        country: 'Finland',
        code: '+358'
    },
    {
        country: 'France',
        code: '+33'
    },
    {
        country: 'Germany',
        code: '+49'
    },
    {
        country: 'Hong Kong',
        code: '+852'
    },
    {
        country: 'India',
        code: '+91'
    },
    {
        country: 'Indonesia',
        code: '+62'
    },
    {
        country: 'Israel',
        code: '+972'
    },
    {
        country: 'Italy',
        code: '+39'
    },
    {
        country: 'Japan',
        code: '+81'
    },
    {
        country: 'Kuwait',
        code: '+965'
    },
    {
        country: 'Malaysia',
        code: '+60'
    },
    {
        country: 'Mauritius',
        code: '+230'
    },
    // {
    //     country: 'Mexico',
    //     code: '+52'
    // },
    {
        country: 'Nepal',
        code: '+977'
    },
    {
        country: 'Netherlands',
        code: '+31'
    },
    {
        country: 'New Zealand',
        code: '+64'
    },
    {
        country: 'Poland',
        code: '+48'
    },
    {
        country: 'Philippines',
        code: '+63'
    },
    {
        country: 'Peru',
        code: '+51'
    },
    {
        country: 'Singapore',
        code: '+65'
    },
    {
        country: 'South Korea',
        code: '+82'
    },
    {
        country: 'Spain',
        code: '+34'
    },
    {
        country: 'Sri Lanka',
        code: '+94'
    },
    {
        country: 'Sweden',
        code: '+46'
    },
    {
        country: 'Switzerland',
        code: '+41'
    },
    {
        country: 'Taiwan',
        code: '+886'
    },
    {
        country: 'Thailand',
        code: '+66'
    },
    {
        country: 'United Kingdom',
        code: '+44'
    }
];

export default postalCodes;