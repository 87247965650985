import React from 'react';
import Layout from '../components/layout';
import StyledHero from "../components/StyledHero";
import Contact from "../components/Contact";
import { graphql } from 'gatsby';
import { getSrc } from "gatsby-plugin-image";
import Seo from '../components/SEO';

const contact = (props) => {
  const { data } = props;
  console.log("comp props:", props);

  const seoImage = {
		src: getSrc(data.seoImage),
		height: data.seoImage.childImageSharp.gatsbyImageData.height,
		width: data.seoImage.childImageSharp.gatsbyImageData.width,
		alt: 'Golden Gate Bridge San Francisco',
  };
  
  return (
    <Layout>
      <Seo
        siteTitle={'Contact'}
        seoTitle='Contact Form for Malaud LLC'
        path={props.location.pathname}
        metaImage={seoImage}
        description='Contact Form for Malaud.com'
        keywords={[]}
        datePublished="04-03-2020"
        dateModified="11-01-2022"
      />

      <StyledHero overlay={false} img={data.defaultBcg.childImageSharp.gatsbyImageData} />
      <StyledHero overlay={false} />
      <Contact />
    </Layout>
  );
}

export default contact

export const contactQuery = graphql`{
  defaultBcg: file(relativePath: {eq: "aboutBanner.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
    }
  }
  seoImage: file(relativePath: {eq: "aboutBanner.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, height: 600)
    }
  }
}
`
