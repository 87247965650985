import React, { Component } from "react"
// import { Link } from 'gatsby'

import Title from "./Title"

import * as styles from "../css/contact.module.css"
// import img from "../images/contact.jpeg"

import { withFormik, Form, Field,  } from "formik";
import * as Yup from "yup";
import axios from "axios";

import ccodes from '../constants/ccodes'

const FORMERROR = {
  UNKNOWN: 0,
  CCODE: 10,
  PHONE: 11,
  EMAIL: 12,
  SUBJECT: 13,
  MESSAGE: 14
};

// const recaptchaKey = '6LfGp_4UAAAAABPn9HwCILeB05aAqLwzj1_9mdXk';
const recaptchaSiteKey = '6LfVXrgZAAAAAPZqa2l_ix7crEvYLuJTt-z_2wJj';

class Contact extends Component {
  
  constructor(props) {
    super(props);
    // console.log("contact props:", props);
    this.classes = props.classes;
  }
	  
  onLoad = () => {
    console.log("recaptcha loaded");
  }
  
  componentDidMount() {
    if (!window.grecaptcha) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://www.google.com/recaptcha/api.js`
      script.async = true
      script.defer = true
      document.body.appendChild(script);
      // const headScript = document.getElementsByTagName('script')[0]
      // headScript.parentNode.insertBefore(script, headScript)
      script.addEventListener('load', () => {
        this.onLoad()
      });

      let self = this;
      var recaptchaVerify = function(response) {
        return new Promise(function(resolve, reject) {
          self.props.setFieldValue('retoken', response);
          resolve();
        });
      }

      window.onRecaptchaVerify = recaptchaVerify;
    
    }
     else {
  		this.onLoad()
	  }
  }

  render() {  
    return (
      <section className={styles.contact}>
        <Title title="Contact" subtitle="Us" />
      
        <h4>Please fill out the following form.</h4>
        
        <div className={styles.contactCenter}>
          
          {/* <div className={styles.contactImg}>
            <div className={styles.imgContainer}>
              <img src={img} alt="contact us" />
            </div>
          </div> */}
        
          <div className={styles.formContainer}>
		        <Form className={styles.form}>

                {/* Email */}
                <div>
                    <Field className={
                      (this.props.status && this.props.status.errorCode === FORMERROR.EMAIL) ||
                        (this.props.touched.email && this.props.errors.email)?
                            `${styles.emailField} ${styles.fieldError}` : 
                            `${styles.emailField}`}
                        name="email" type="email" placeholder=" Email *" value={this.props.values.email} />
                </div>

                {/* Name */}
                <div className={styles.name}>
                    <div>
                        <Field className={
                            (this.props.touched.firstname && this.props.errors.firstname)?
                            `${styles.firstnameField} ${styles.fieldError}` : 
                            `${styles.firstnameField}`} 
                        name="firstname" type="text" placeholder=" First name *" value={this.props.values.firstname} />
                    </div>
                    <div>
                        <Field className={
                            (this.props.touched.lastname && this.props.errors.lastname)?
                            `${styles.lastnameField} ${styles.fieldError}` : 
                            `${styles.lastnameField}`}
                        name="lastname" type="text" placeholder=" Last name *" value={this.props.values.lastname} />
                    </div>
                </div>

                {/* Phone */}
                <div className={styles.phone}>
                    <div>
                        <Field className={
                            (this.props.status && this.props.status.errorCode === FORMERROR.CCODE) ||
                            (this.props.touched.ccode && this.props.errors.ccode)?
                                `${styles.ccodeField} ${styles.fieldError}` : 
                                `${styles.ccodeField}`}
                                as="select" name="ccode">
                            <option value="">Country code *</option>
                            {ccodes
                                .sort((a,b) => (a.country > b.country) ? 1 : ((b.country > a.country) ? -1 : 0))
                                .map(ccode => {
                                    return <option key={ccode.country} value={ccode.code}>{ccode.country} {ccode.code}</option>
                                })
                            }
                        </Field>
                    </div>
                    <div>
                      <Field className={
                          (this.props.status && this.props.status.errorCode === FORMERROR.PHONE) ||
                          (this.props.touched.phone && this.props.errors.phone)?
                              `${styles.phoneField} ${styles.fieldError}` : 
                              `${styles.phoneField}`}
                          name="phone" type="phone" placeholder=" Phone *" />
                    </div>
                </div>
                
                {/* Subject */}
                <div className={styles.subject}>
                    {/* <label className={styles.subjectLabel} htmlFor="Subject">Select Subject:</label> */}
                    <Field className={
                        (this.props.status && this.props.status.errorCode === FORMERROR.SUBJECT) ||
                        (this.props.touched.subject && this.props.errors.subject)?
                            `${styles.subjectField} ${styles.fieldError}` : 
                            `${styles.subjectField}`}
                            as="select" name="subject">
                        <option value="">Subject *</option>
                        {/* <option value="app_actiontimer">ActionTimer for Mac app</option> */}
                        <option value="frontend_dev">Static website development</option>
                        <option value="backend_dev">Backend server development</option>
                        <option value="aws_dev">AWS Infrastructure</option>
                        <option value="seo_dev">Website SEO consultation</option>
                        <option value="other_question">Other question</option>
                    </Field>
                </div>
                
                {/* Message */}
                <div className={styles.section}>
                    <Field className={
                        (this.props.status && this.props.status.errorCode === FORMERROR.MESSAGE) ||
                        (this.props.touched.message && this.props.errors.message)?
                            `${styles.messageField} ${styles.fieldError}` : 
                            `${styles.messageField}`}
                        name="message" as="textarea" placeholder=" Message *" value={this.props.values.message} />
                </div>

                <div className={styles.mCaptcha}>
                  <div className="g-recaptcha" data-sitekey={recaptchaSiteKey} data-callback="onRecaptchaVerify"></div>
                </div>

                <div>
                    <Field name="user_id" type="hidden"/>
                </div>

              <div className={styles.submit}>
                <button type="submit" className={styles.submitButton} disabled={this.props.isSubmitting || !this.props.isValid}>
                  Submit
                </button>

                { this.props.isSubmitting && this.props.status && this.props.status.msg && 
                    alert(this.props.status.msg)
                }
              </div>

            </Form>
        </div>

      </div>

    </section>
  )
  }
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const FormikContact = withFormik({
    mapPropsToValues({ email }) {
        return {
            firstname: '',
            lastname: '',
            email: '',
            ccode: '',
            phone: '',
            subject: '',
            message: '',
            retoken: '',
            user_id: ''
        }
    },
    mapPropsToErrors() {
      return {
          email: ''
      }
    },    
    validationSchema: Yup.object().shape({
        firstname: Yup.string().max(16).required(),
        lastname: Yup.string().max(16).required(),
        email: Yup.string().email().required(),
        ccode: Yup.string().required(),
        phone: Yup.string().matches(phoneRegExp).required(),
        subject: Yup.string().required(),
        message: Yup.string().max(240).required(),
        retoken: Yup.string().required()
    }),
    validateOnBlur: true,
    validateOnChange: true,
    handleSubmit(values, { resetForm, setSubmitting, setStatus}) {
      setStatus({msg:'', errorCode: 0});
      setTimeout(() => {
		    // setStatus({msg:'Message sent successfully!', errorCode: 0});
            if (window.grecaptcha) {
                console.log("token:", values.retoken)
                console.log("values:", values);
                const formdata = {
                  firstname: values.firstname,
                  lastname: values.lastname,
                  email: values.email,
                  ccode: values.ccode,
                  phone: values.phone,
                  subject: values.subject,
                  message: values.message,
                  retoken: values.retoken,
                  user_id: values.user_id
                }
                console.log("formdata:", formdata)

                axios.post('https://api.malaud.com/contact', formdata)
                .then(res => {
                    console.log("got result:", res);
                    if (res.data) {
                      if(res.data.success === false) {
                        if (res.data.errorCode === 0) {
                          setStatus({msg: 'Failed to send message', errorCode: res.data.errorCode});
                        } else {
                          setStatus({msg: 'Error in form', errorCode: res.data.errorCode});
                        }
                      } else {
                        setStatus({msg:'Message sent successfully!', errorCode: 0});
                        setTimeout(() => {
                          resetForm();
                          window.grecaptcha.reset();
                        }, 2000);
                      }
                    } // if res.data
                })
                .catch(err => {
                    console.log("got error:", err);
                    setStatus({msg: err, errorCode: 0});
                })
                .finally(() => {
                    setSubmitting(false)
                });
              } else {
                console.log("recaptcha not loaded")
              }        
        }, 2000)
    }
}) (Contact)

export default FormikContact
