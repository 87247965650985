// extracted by mini-css-extract-plugin
export var contact = "contact-module--contact--efa9q";
export var form = "contact-module--form--wGgUv";
export var section = "contact-module--section--X47Gr";
export var emailField = "contact-module--email-field--8WvZT";
export var firstnameField = "contact-module--firstname-field--n2ZfA";
export var lastnameField = "contact-module--lastname-field--+M+Ya";
export var ccodeField = "contact-module--ccode-field--x4mGI";
export var phoneField = "contact-module--phone-field--SIIZy";
export var subjectField = "contact-module--subject-field--PSrEw";
export var name = "contact-module--name--pDxhn";
export var phone = "contact-module--phone---OUM-";
export var subject = "contact-module--subject--l4EO1";
export var fieldError = "contact-module--field-error--tcvw5";
export var messageField = "contact-module--message-field--aGT7M";
export var mCaptcha = "contact-module--m-captcha--LVkBT";
export var submit = "contact-module--submit---AHiT";
export var submitButton = "contact-module--submit-button--5Z0KE";
export var confirmMessage = "contact-module--confirm-message--hAM9X";
export var formContainer = "contact-module--form-container--RAAFg";
export var contactCenter = "contact-module--contact-center--4BFgk";